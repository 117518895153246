<template>
  <section class="home-stats">
    <div class="container">
      <div class="wrapper mb-l">
        <div class="wrapper-items">
          <div class="item">
            <div class="wrapper-title">
              <p class="fs--large number">
                3269
              </p>
              <p class="fs--medium fw--bold">
                ключей
              </p>
            </div>
            <button class="btn-main">
              <icon-key />
            </button>
          </div>
          <div class="item">
            <div class="wrapper-title">
              <p class="fs--large number">
                1922
              </p>
              <p class="fs--medium fw--bold">
                документа
              </p>
            </div>
            <button class="btn-main">
              <icon-passport />
            </button>
          </div>
          <div class="item">
            <div class="wrapper-title">
              <p class="fs--large number">
                283
              </p>
              <p class="fs--medium fw--bold">
                устройства
              </p>
            </div>
            <button class="btn-main">
              <icon-phone />
            </button>
          </div>
          <div class="item">
            <div class="wrapper-title">
              <p class="fs--large number">
                321
              </p>
              <p class="fs--medium fw--bold">
                питомец
              </p>
            </div>
            <button class="btn-main">
              <icon-dog />
            </button>
          </div>
        </div>
        <div class="already-returned">
          <icon-dog class="icon icon-dog" />
          <icon-key class="icon icon-key" />
          <icon-passport class="icon icon-passport" />
          <icon-phone class="icon icon-phone" />
          <span class="already fs--large">Уже</span>
          <span>Возвращено</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconKey from '@/assets/img/icons/key.svg'
import IconPassport from '@/assets/img/icons/passport.svg'
import IconPhone from '@/assets/img/icons/phone.svg'
import IconDog from '@/assets/img/icons/dog.svg'

export default {
  name: 'HomeStats',
  components: {
    IconKey,
    IconPassport,
    IconPhone,
    IconDog
  },
  beforeMount () {
    this.setAnimation()
  },
  methods: {
    setAnimation () {
      const iconArr = window.innerWidth > 768 ? [ { name: '.icon-key', top: '20px', left: '-450px' }, { name: '.icon-passport', top: '100px', left: '-450px' }, { name: '.icon-phone', top: '170px', left: '-450px' }] : [ { name: '.icon-key', top: '280px', left: '10px' }, { name: '.icon-passport', top: '350px', left: '65px' }, { name: '.icon-phone', top: '400px', left: '120px' } ] // массив с названиями классов иконок и точкой задающей направление движения
      for (let i = 0; i < iconArr.length; i++) {
        setTimeout(() => {
          this.$anime({
            targets: iconArr[i].name,
            top: iconArr[i].top,
            left: iconArr[i].left,
            rotate: 90,
            scale: 2,
            duration: 8000,
            opacity: 0,
            loop: true,
            easing: 'easeInOutExpo'
          })
        }, i * 3500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .home-stats {
    position: relative;
    &.active {
      .wrapper-items {
        top: 0 !important;
      }
      .already-returned {
        bottom: 0 !important;
      }
    }
    &:before {
      z-index: -1;
      content: '';
      background: url('~@/assets/img/home/stats/man.png') center / cover no-repeat;
      position: absolute;
      left: -100px;
      bottom: -160px;
      width: 366px;
      height: 708px;
      opacity: .2;
      transform: scale(-1, 1);
      @include max-w-sm {
        width: 150px;
        height: 304px;
        left: -40px;
        bottom: calc(50% - 152px);
      }
    }
    .container {
      min-height: 100vh;
      align-items: center;
      justify-content: center;
      .wrapper {
        position: relative;
        max-width: 1000px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: url('~@/assets/img/home/stats/pipes.png') center / contain no-repeat;
        background-position-x: calc(50% + 68px);
        @include max-w-lg {
          background-position-x: calc(50% + 140px);
          max-width: 700px;
        }
        @include max-w-sm {
          background: url('~@/assets/img/home/stats/mobile-pipes.png') center / auto no-repeat;
          flex-direction: column-reverse;
        }
        @include max-w-xs {
          background-position-x: calc(50% + 25px);
        }
        .wrapper-items, .already-returned {
          position: relative;
          transition: 1s;
        }
        .wrapper-items {
          top: -2000px;
          min-width: 250px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          @include max-w-lg {
            min-width: 150px;
          }
          @include max-w-sm {
            padding-top: 160px;
            padding-right: 100px;
            align-self: center;
            align-items: flex-start;
          }
          @include max-w-xs {
            padding-top: 140px;
            padding-right: 0px;
          }
          .item {
            position: relative;
            margin-bottom: 34px;
            display: flex;
            align-items: center;
            &:last-child {
              margin-bottom: 0;
            }
            @include max-w-sm {
              margin-bottom: 0;
              &:nth-child(2) {
                padding-left: 45px;
              }
              &:nth-child(3) {
                padding-left: 100px;
              }
              &:last-child {
                padding-left: 180px;
              }
            }
            @include max-w-xs {
              margin-bottom:  15px;
              &:first-child {
                margin-left: -20px;
              }
              &:nth-child(2) {
                padding-left: 10px;
              }
              &:nth-child(3) {
                padding-left: 64px;
              }
              &:last-child {
                padding-left: 140px;
              }
            }
            .wrapper-title {
              line-height: 1;
              text-align: right;
              .number {
                font-weight: 900;
              }
            }
            .btn-main {
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 56px;
              height: 80px;
              margin-left: 16px;
              @include max-w-sm {
                width: 52px;
                height: 66px;
              }
              @include max-w-xs {
                width: 40px;
                height: 50px;
              }
            }
          }
        }
        .already-returned {
          bottom: -2000px;
          z-index: 3;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 294px;
          height: 294px;
          background: linear-gradient(180deg, rgba(250, 250, 250, 0.24) 0%, rgba(255, 255, 255, 0.24) 100%);
          backdrop-filter: blur(8px);
          text-transform: uppercase;
          color: $blue;
          @include max-w-lg {
            width: 200px;
            height: 200px;
          }
          @include max-w-xs {
            margin-left: 60px;
          }
          &::before {
            z-index: 1;
            content: '';
            position: absolute;
            left: calc(50% - 91px);
            top: calc(50% - 91px);
            width: 182px;
            height: 182px;
            background: $white;
            border-radius: 50%;
            @include max-w-lg {
              left: calc(50% - 65px);
              top: calc(50% - 65px);
              width: 130px;
              height: 130px;
            }
          }
          span {
            z-index: 4;
          }
          .already {
            line-height: 1;
            font-weight: 900;
          }
          .icon {
            position: absolute;
            z-index: -1;
          }
        }
      }
    }
  }
</style>
