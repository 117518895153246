<template>
  <section class="home-about">
    <div class="container">
      <div class="wrapper">
        <div class="click">
          <span>CLICK</span>
          <p :class="{ active : activeBackground === 1 }">
            CLICK
          </p>
          <p :class="{ active : activeBackground === 2 }">
            CLICK
          </p>
          <p :class="{ active : activeBackground === 3 }">
            CLICK
          </p>
          <p :class="{ active : activeBackground === 4 }">
            CLICK
          </p>
        </div>
        <div class="content">
          <h1 class="section-title">
            О нас
          </h1>
          <div class="divider" />
          <div class="wrapper-description">
            <p class="description">
              Компания КликТуАйДи предоставляет инновационный сервис на рынке поддержки клиентов в случаях, когда потерялись Ваши личные вещи, близкие люди или питомцы.
            </p>
            <p class="description">
              Качественный сервис, профессиональный и высокий уровень обслуживания клиентов позволили заработать лояльность по всему миру.
            </p>
            <p>
              Благодаря предварительной маркировке личных вещей специальными метками (брелоками, наклейками, браслетами, кулонами, багажными бирками) с уникальным идентификационным (ID) номером, а также уникальным разработкам в области мобильных приложений по защите личных вещей, КликТуАйДи эффективно помогает вернуть владельцу потерянные вещи из любой точки мира.
            </p>
          </div>
          <app-button class="mb-l" title="Скачать презентацию" description="PDF, 30 МБ">
            <icon-arrow-left class="icon arrow-down" />
          </app-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import IconArrowLeft from '@/assets/img/icons/arrow-left.svg'

export default {
  name: 'HomeAbout',
  components: {
    AppButton,
    IconArrowLeft
  },
  data () {
    return {
      activeBackground: 1
    }
  },
  beforeMount () {
    this.setAnimation()
  },
  methods: {
    setAnimation () {
      setInterval(() => {
        if (this.activeBackground === 4) {
          this.activeBackground = 1
        }
        this.activeBackground++
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .home-about {
    &.active {
      .click {
        left: 0;
      }
      .content {
        right: 0;
      }
    }
    .container {
      height: 100%;
      justify-content: center;
      .wrapper {
        height: calc(100% - 140px);
        display: flex;
        align-items: center;
        @include max-w-sm {
          flex-direction: column;
        }
      }
    }
  }
  .click, .content {
    position: relative;
    transition: 1s;
  }
  .click {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'SimplyRounded', sans-serif;
    left: -2000px;
    margin-right: 80px;
    line-height: 2;
    font-size: 228px;
    font-weight: 400;
    @include max-w-lg {
      font-size: 168px;
    }
    @include max-w-md {
      font-size: 128px;
    }
    @include max-w-sm {
      margin-right: 0;
      max-height: 140px;
    }
    @include max-w-xs {
      font-size: 120px;
    }
    span {
      line-height: 1;
      opacity: 0;
    }
    p {
      line-height: 1;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-background-clip: text !important;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: 5s ease-in-out;
      &.active {
        background-size: auto 200% !important;
        opacity: 1 !important;
        &:nth-child(5) {
          background-size: 200% auto !important;
        }
      }
      &:nth-child(2) {
        background: url('~@/assets/img/home/about/img-about-1.jpg') center / auto 150% no-repeat;
        opacity: 0;
      }
      &:nth-child(3) {
        background: url('~@/assets/img/home/about/img-about-2.jpg') center / auto 150% no-repeat;
        opacity: 0;
      }
      &:nth-child(4) {
        background: url('~@/assets/img/home/about/img-about-3.jpg') center / auto 150% no-repeat;
        opacity: 0;
      }
      &:nth-child(5) {
        background: url('~@/assets/img/home/about/img-about-4.jpg') center / 150% auto no-repeat;
        opacity: 0;
      }
    }
  }
  .content {
    right: -2000px;
    max-width: 420px;
    .wrapper-description {
      margin-bottom: 40px;
      .description {
        margin-bottom: 1em;
      }
    }
  }
  .wrapper-btn {
    max-width: 300px;
  }
  .arrow-down {
    transform: rotate(-90deg);
  }
</style>
