<template>
  <section class="home-how">
    <div class="container">
      <div class="wrapper">
        <div class="content ta--center mb-xl">
          <h1 class="section-title">
            Как мы помогаем возвращать <br> ваши <span class="btn-slider-change td--underline" :class="{ blue : activeSwiper === 'Sally' }" @click="changeSwiper('George')">вещи</span> и <span class="btn-slider-change td--underline" :class="{ blue : activeSwiper === 'George' }" @click="changeSwiper('Sally')">питомцев</span>
          </h1>
          <div class="divider" />
          <p>
            Нас рекомендуют друзьям, коллегам, знакомым. Мы всегда готовы к сотрудничеству <br> как с детскими садами и школами, так и с малым, средним и крупным бизнесом.
          </p>
        </div>
        <div class="wrapper-phone">
          <img src="@/assets/img/home/how/device.png" alt="">
          <div class="wrapper-phone-content">
            <div class="wrapper-progress">
              <div
                v-for="item in swiperContent[activeSwiper]"
                :key="item.id"
                class="progress-bar"
                :class="{ active : item.active }"
              />
            </div>
            <div class="wrapper-logo">
              <logo-svg class="icon-logo" />
              <!-- <span>CLICK2ID</span> -->
            </div>
            <div class="wrapper-swiper">
              <swiper
                ref="swiper"
                class="swiper"
                :options="swiperOptions"
                @slide-change="slideChange"
              >
                <swiper-slide
                  v-for="item in swiperContent[activeSwiper]"
                  :key="item.id"
                >
                  <speech-balloon :content="item.content" :position="item.speechPosition" />
                  <div class="wrapper-img">
                    <img :src="require(`@/assets/img/home/how/${item.img}`)" alt="Saly">
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="wrapper-btn">
              <div slot="button-prev" class="swiper-btn swiper-btn-prev">
                <icon-arrow-left class="icon-arrow arrow-left" />
              </div>
              <div slot="button-next" class="swiper-btn swiper-btn-next">
                <icon-arrow-right class="icon-arrow arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LogoSvg from '@/assets/img/logo.svg'
import SpeechBalloon from '@/components/SpeechBalloon'
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'
import IconArrowLeft from '@/assets/img/icons/arrow-left.svg'

export default {
  name: 'HomeHow',
  components: {
    LogoSvg,
    SpeechBalloon,
    IconArrowRight,
    IconArrowLeft
  },
  data () {
    return {
      activeSwiper: 'Sally',
      swiperOptions: {
        autoplay: {
          delay: 10000
        },
        effect: 'fade',
        slidesPerView: 1,
        speed: 300,
        fadeEffect: {
          crossFade: true
        },
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        }
      },
      swiperContent: {
        'Sally': [
          {
            id: 1,
            img: 'Saly-1.png',
            content: 'Однажды Салли поехала в долгожданный отпуск заграницу',
            speechPosition: 'bottom-right',
            active: true
          },
          {
            id: 2,
            img: 'Saly-2.png',
            content: 'Она взяла с собой своего четвероногого друга - Джека, но не успела оглянуться, как щенок сбежал',
            speechPosition: 'top-right',
            active: false
          },
          {
            id: 3,
            img: 'Saly-3.png',
            content: 'Джек оставшись один стал звать свою хозяйку',
            speechPosition: 'bottom-right',
            active: false
          },
          {
            id: 4,
            img: 'Saly-4.png',
            content: 'Недалеко оказался Тревис и услышал лай Джека',
            speechPosition: 'top-left',
            active: false
          },
          {
            id: 5,
            img: 'Saly-5.png',
            content: 'Тревис нашел щенка, и увидел на ошейнике метку CLICK',
            speechPosition: 'top-right',
            active: false
          },
          {
            id: 6,
            img: 'Saly-6.png',
            content: 'Он связался с нами по указанным данным',
            speechPosition: 'bottom-right',
            active: false
          },
          {
            id: 7,
            img: 'Saly-7.png',
            content: 'Мы сообщили Салли о том где сейчас Джек, и связали ее с Тревисом',
            speechPosition: 'bottom-left',
            active: false
          },
          {
            id: 8,
            img: 'Saly-8.png',
            content: 'И Салли смогла забрать питомца',
            speechPosition: 'top-left',
            active: false
          },
          {
            id: 9,
            img: 'Saly-9.png',
            content: 'Джек успешно оказался дома, а Тревис получил вознаграждение за оказанную помощь',
            speechPosition: 'top-right',
            active: false
          }
        ],
        'George': [
          {
            id: 1,
            img: 'George-1.png',
            content: 'Это Джордж, и он усердный программист, но немного рассенный',
            speechPosition: 'bottom-right',
            active: true
          },
          {
            id: 2,
            img: 'George-2.png',
            content: 'Однажды он забыл свой телефон в автобусе, когда возвращался из офиса',
            speechPosition: 'top-right',
            active: false
          },
          {
            id: 3,
            img: 'Saly-7.png',
            content: 'Салли, зайдя на следующей остановке нашла устройство, но оно было разряжено. Поэтому она позвонила по данным на метке CLICK',
            speechPosition: 'bottom-right',
            active: false
          },
          {
            id: 4,
            img: 'George-3.png',
            content: 'Мы разобрались в ситуации, и договорились с Джорджем о времени и месте возврата устройства',
            speechPosition: 'top-left',
            active: false
          },
          {
            id: 5,
            img: 'George-4.png',
            content: 'В итоге мы доставили телефон обратно Джорджу, а Салли получила вознаграждение за свою внимательность',
            speechPosition: 'top-right',
            active: false
          }
        ]
      }
    }
  },
  methods: {
    changeSwiper (name) {
      this.swiperContent[this.activeSwiper][this.$refs.swiper.$swiper.activeIndex].active = false
      this.swiperContent[name][0].active = true
      this.activeSwiper = name
      this.toFirstSlide()
    },
    toFirstSlide () {
      this.$refs.swiper.$swiper.slideTo(0)
    },
    slideChange (e) {
      this.swiperContent[this.activeSwiper][e.previousIndex].active = false
      this.swiperContent[this.activeSwiper][this.$refs.swiper.$swiper.activeIndex].active = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .home-how {
    background: url('~@/assets/img/home/how/how-pipe.png') center -1000px / cover no-repeat;
    transition: 1s;
    position: relative;
    @include max-w-xs {
      background: none;
    }
    &.active {
      background-position: top;
      .wrapper {
        .content {
          margin: 0 auto 40px auto;
          position: relative;
          top: 0;
        }
        .wrapper-phone {
          bottom: 0;
        }
      }
    }
    .content, .wrapper-phone {
      transition: 1s;
    }
    .container {
      height: 100%;
    }
    .wrapper {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        max-width: 1058px;
        .blue {
          color: $blue;
        }
      }
      .btn-slider-change {
        cursor: pointer;
        transition: .3s;
      }
      .wrapper-phone {
        position: relative;
        margin-top: auto;
        width: auto;
        max-width: 40%;
        background: #FFF6EC;
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        @include max-w-xl {
          max-width: 30%;
        }
        @include max-w-lg {
          max-width: 40%;
        }
        @include max-w-sm {
          max-width: 60%;
        }
        @include max-w-xs {
          max-width: 70%;
        }
        .wrapper-phone-content {
          max-width: 84%;
          z-index: 9;
          position: absolute;
          top: 10%;
          left: 8%;
          height: 90%;
          .wrapper-progress {
            margin-right: -2px;
            margin-left: -2px;
            margin: 6px 0;
            display: flex;
            align-items: center;
            .progress-bar {
              border-radius: 2px;
              margin: 0 2px;
              height: 2px;
              width: 100%;
              background: $dark;
              transition: .3s;
              opacity: 0.2;
              &.active {
                opacity: 1;
              }
            }
          }
          .wrapper-logo {
            display: flex;
            align-items: center;
            span {
              font-size: 10px;
              margin-top: 5px;
            }
            .icon-logo {
              margin-right: 8px;
              width: 48px;
              height: 20px;
            }
          }
          .wrapper-swiper {
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .swiper {
            max-width: 100%;
            overflow: visible;
            .wrapper-img {
              width: 80%;
              margin: 0 auto;
              @include max-w-xs {
                width: 80%;
                border-radius: $border-radius-base;
              }
              img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
          }
          .wrapper-btn {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            top: 50%;
            .swiper-btn {
              z-index: 9;
              position: absolute;
              width: 24px;
              height: 24px;
              cursor: pointer;
              transition: .3s;
              @include max-w-xs {
                transform: scale(0.8);
              }
              &.swiper-btn-disabled {
                opacity: 0;
              }
              &.swiper-btn-prev {
                left: -100px;
                @include max-w-sm {
                  left: -50px;
                }
              }
              &.swiper-btn-next {
                right: -100px;
                @include max-w-sm {
                  right: -50px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>